import React from "react";
import NewestItem from "@/components/home/Newest/NewestItem";

const Newest = ({ highLights }) => (
  <>
    <h2 className="mt-16 text-center text-xl font-semibold text-orange lg:text-left lg:text-4xl">Neu und im Trend</h2>
    <div className="flex">
      <div className="mt-4 flex overflow-y-hidden lg:mb-4 lg:mt-8 lg:overflow-visible ">
        <div className="mr-4 h-full w-full rounded-xl border border-purple-800 bg-white p-6 shadow sm:mt-0 sm:inline lg:mt-10 lg:w-1/2 lg:shadow-xl">
          <h3 className="pb-4 text-lg font-semibold">Neueste Berater</h3>
          {highLights.lastConsultants.map((consultant) => (
            <NewestItem
              link={consultant.slug}
              title={consultant.title}
              subtitle={consultant.subTitle}
              picture={consultant.image}
              state={consultant.state}
            />
          ))}
        </div>

        <div className="mr-4 h-full w-full rounded-xl border border-purple-800 bg-white p-6 shadow sm:mt-0 sm:inline lg:mt-10 lg:w-1/2 lg:shadow-xl">
          <h3 className="pb-4 text-lg font-semibold">Beliebteste Berater</h3>
          {highLights.popularConsultants.map((consultant) => (
            <NewestItem
              link={consultant.slug}
              title={consultant.title}
              subtitle={consultant.subTitle}
              picture={consultant.image}
              state={consultant.state}
            />
          ))}
        </div>

        <div className="h-full w-full rounded-xl border border-purple-800 bg-white p-6 shadow sm:mt-0 sm:inline lg:mt-10 lg:w-1/2 lg:shadow-xl">
          <h3 className="pb-4 text-lg font-semibold">Meistgelesene Artikel</h3>
          {highLights.popularBlogs.map((blog) => (
            <NewestItem
              link={`article/${blog.slug}`}
              title={blog.title}
              subtitle={blog.subTitle}
              picture={blog.image}
            />
          ))}
        </div>
      </div>
    </div>
  </>
);

export default Newest;
