import { Swiper, SwiperSlide } from "swiper/react";
import Link from "next/link";
import SwiperCore, { Mousewheel } from "swiper";
import isMobile from "is-mobile";
import ButtonCategory, { BUTTON_CATEGORY_TYPES } from "@/components/common/ButtonCategory";
import "swiper/css";
import styles from "@/components/common/Slider.module.css";
import clsx from "clsx";

SwiperCore.use([Mousewheel]);

const CategorySlider = ({ mainCategories }) => (
  <Swiper
    slidesPerView="auto"
    mousewheel={{ forceToAxis: true }}
    className={clsx(styles.slider, "[--slider-space-between:10px] lg:[--slider-space-between:24px]")}
    cssMode={isMobile()}
  >
    {mainCategories.map(({ main }) => (
      <SwiperSlide key={main.id} className="pt-6 lg:pt-8">
        <Link href={`/category/${main.slug}`} passHref>
          <ButtonCategory type={BUTTON_CATEGORY_TYPES.CATEGORY} tag="a">
            {main.name}
          </ButtonCategory>
        </Link>
      </SwiperSlide>
    ))}
  </Swiper>
);

export default CategorySlider;
