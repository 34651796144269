import { forwardRef } from "react";
import Link from "next/link";

const HighlightLink = ({ children, categoryId }, ref) => (
  <Link href={`/category/${categoryId}`}>
    <a className="button-text block p-0 text-xl text-orange hover:text-orange-900 lg:inline lg:text-4xl" ref={ref}>
      {children}
    </a>
  </Link>
);

export default forwardRef(HighlightLink);
