import useSWR from "swr";

export default function useConsultantsByCategories(fallbackData) {
  const { data } = useSWR("/consultants/categories", {
    fallbackData,
    revalidateOnMount: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return data;
}
