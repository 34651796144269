import React from "react";
import Link from "next/link";
import useIsMobile from "@/common/hook/useIsMobile";

const CrossLink = ({ className, children, href }) => {
  const isMobile = useIsMobile();

  return (
    <Link href={href}>
      <a target={isMobile ? undefined : "_blank"} className={className}>
        {children}
      </a>
    </Link>
  );
};

export default CrossLink;
