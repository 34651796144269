import useTranslation from "next-translate/useTranslation";
import SearchBar from "@/components/common/SearchBar";

const SearchSection = () => {
  const { t } = useTranslation("home");

  return (
    <div className="mt-6 h-12 lg:h-18">
      <SearchBar inputProps={{ placeholder: t`search-placeholder` }} />
    </div>
  );
};

export default SearchSection;
