
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Layout from "@/components/core/Layout";
import HeroBanner from "@/components/home/HeroBanner";
import request from "@/utils/request";
import BannerCategories from "@/components/home/BannerCategories";
import SearchSection from "@/components/home/SearchSection";
import CategorySlider from "@/components/home/CategorySlider";
import ConsultantSliders from "@/components/home/CategorySliders";
import { NextSeo } from "next-seo";

const HomePage = ({
  activeCategory,
  featuredCategories,
  mainCategories,
  advertisement,
  blogPosts,
  headersCategories,
  consultants: initialConsultants,
  highLights,
}) => (
  <Layout showSearch>
    <NextSeo
      canonical="https://www.vialantis.com"
      languageAlternates={[
        {
          hrefLang: "de",
          href: `${process.env.BASE_URL}/de`,
        },
        {
          hrefLang: "en",
          href: `${process.env.BASE_URL}/en`,
        },
        {
          hrefLang: "x-default",
          href: `${process.env.BASE_URL}`,
        },
      ]}
    />
    <BannerCategories mainCategories={headersCategories} />
    <HeroBanner />

    <div className="container py-4 xl:py-6">
      <SearchSection />
      <CategorySlider mainCategories={mainCategories} />
      <ConsultantSliders
        initialConsultants={initialConsultants}
        blogPosts={blogPosts}
        advertisement={advertisement}
        activeCategory={activeCategory}
        featuredCategories={featuredCategories}
        highLights={highLights}
      />
      {/* <UserComments /> */}
    </div>
  </Layout>
);

 async function _getStaticProps({ locale }) {
  const [
    { data: consultants },
    { data: categories },
    { data: advertisement },
    { data: blogPosts },
    { data: highLights },
  ] = await Promise.all([
    request.get("/consultants/categories", { headers: { "Accept-Language": locale } }),
    request.get("/categories", { headers: { "Accept-Language": locale } }),
    request.get("/advertisement", { headers: { "Accept-Language": locale } }),
    request.get("/blog-posts/featured-categories", { headers: { "Accept-Language": locale } }),
    request.get("/highlights", { headers: { "Accept-Language": locale } }),
  ]);

  let activeCategory = null;
  const featuredCategories = [];
  const mainCategories = [];
  const headersCategories = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const category of categories) {
    const { featured: isFeatured, main: isMain, showInHead } = category.main;
    if (isFeatured) {
      if (activeCategory === null && isMain) {
        activeCategory = category;
      } else {
        featuredCategories.push(category);
      }
    }

    if (isMain) {
      mainCategories.push(category);
    }

    if (showInHead) {
      headersCategories.push(category);
    }
  }

  return {
    props: {
      activeCategory,
      featuredCategories,
      blogPosts,
      mainCategories,
      headersCategories,
      advertisement,
      consultants,
      highLights,
    },
    revalidate: 60,
  };
}

export default HomePage;


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  