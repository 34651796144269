import { useCallback } from "react";
import request from "@/utils/request";
import { useDispatch } from "react-redux";
import { setChatOpen, setChatReceiverId } from "@/store/chatSlice";

export default function usePingChat() {
  const dispatch = useDispatch();

  return useCallback(
    (consultant) => {
      dispatch(setChatOpen(true));

      request.get(`/chat-ping/${consultant.id}`).then(
        () => dispatch(setChatReceiverId(consultant.id)),
        () => dispatch(setChatOpen(false))
      );
    },
    [dispatch]
  );
}
