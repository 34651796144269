import React from "react";
import Link from "next/link";
import Image from "next/image";
import IconState from "@/common/icons/online-state.svg";
import UserState from "@/common/models/UserState";

const NewestItem = ({ link, title, subtitle, picture, state = null }) => (
  <div className="my-2 flex space-x-6 rounded px-2 pt-2 hover:bg-purple-700">
    <div className="flex shrink-0 overflow-hidden">
      <Link href={link} passHref>
        <a>
          <div className="relative h-16 w-16">
            <Image
              unoptimized
              src={picture}
              author={title}
              className="rounded-full object-cover"
              width="60"
              height="60"
            />

            {state && <IconState className={`absolute bottom-0.5 left-0.5 h-auto w-6 ${UserState[state].textColor}`} />}
          </div>
        </a>
      </Link>
    </div>
    <div className="flex flex-col justify-between align-middle text-base">
      <Link href={link} passHref>
        <a className="h-[60px] font-semibold">
          <div className="line-clamp-1"> {title}</div>
          <div className="text-sm font-light text-grey-500 line-clamp-1">{subtitle}</div>
        </a>
      </Link>
    </div>
  </div>
);

export default NewestItem;
