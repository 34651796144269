import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import Link from "next/link";
import PlaySoundWithText from "@/components/home/HeroBanner/PlaySoundWithText";
import MobileSolutions from "./MobileSolutions";

const HeroBannerImage = dynamic(() => import("./HeroBannerImage"), {
  ssr: false,
});

const HeroBanner = () => {
  const { t } = useTranslation("home");

  return (
    <>
      <div className="bg-purple-800 pt-3">
        <div className="container flex items-center justify-between xl:gap-8">
          <div className="z-10 w-2/3 space-y-3 py-4 lg:space-y-6 xl:w-2/5 2xl:w-[35%]">
            <h1 className="first-line:text-gradient font-title text-2xl font-bold md:text-3xl lg:text-6xl">{t`banner.title`}</h1>
            <div className="font-sans text-xs leading-5 text-grey-800 md:text-lg lg:text-xl lg:leading-[2.25rem]">
              {t`banner.subtitle`}
            </div>
            <Link href="/features">
              <a className="button-lg inline-block">{t`banner.button`}</a>
            </Link>
            <PlaySoundWithText
              sound="https://vialantis.fra1.cdn.digitaloceanspaces.com/static/sound/vialantis-hello.mp3"
              text="Was ist Vialantis? (44 Sek.)"
            />
          </div>
          <div className="ml-[-50%] flex-1 self-end md:ml-[-20%] md:translate-x-0 xl:ml-0 xl:w-1/2 xl:flex-none">
            <div className="relative w-full pb-[80%]">
              <div className="absolute inset-0 h-full w-full">
                <HeroBannerImage />
              </div>
              <div className="hidden xl:block">
                <div className="absolute top-24 -left-14 z-10 flex w-72 items-center rounded-lg bg-white p-3 2xl:top-36">
                  <div className="absolute -top-7 -right-7 z-10">
                    <Image src="/home/hero/chat-2.svg" alt="Message Icon" width={48} height={48} />
                  </div>

                  <div className="mr-3 flex shrink-0">
                    <Image src="/home/hero/chat.svg" width={60} height={80} alt="Note Icon" />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <h2 className="font-sans text-base font-semibold">{t`banner.chat-advice.title`}</h2>
                    <p className="font-sans text-xs text-grey-700">{t`banner.chat-advice.subtitle`}</p>
                  </div>
                </div>
                <div className="absolute bottom-24 z-10 hidden w-[22rem] items-center rounded-lg bg-white py-3 pr-6 pl-3 lg:-left-10 lg:flex xl:-left-16">
                  <div className="mr-3 flex shrink-0">
                    <Image src="/home/hero/call.svg" width={48} height={48} alt="Phone Icon" />
                  </div>
                  <div>
                    <h2 className="font-sans text-base font-semibold">{t`banner.telephone.title`}</h2>
                    <p className="mt-1 font-sans text-xs leading-5 text-grey-700">{t`banner.telephone.subtitle`}</p>
                  </div>
                </div>
                <div className="absolute top-44 right-0 z-10 hidden w-44 flex-col rounded-lg bg-white p-6 lg:flex">
                  <div className="flex shrink-0">
                    <Image src="/home/hero/video.svg" width={149} height={80} alt="Video Icon" />
                  </div>
                  <div className="mt-5">
                    <h2 className="text-center font-sans text-base font-semibold">{t`banner.video-advice.title`}</h2>
                    <p className="mt-1 text-center font-sans text-xs leading-5 text-grey-700">
                      {t`banner.video-advice.subtitle`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileSolutions />
    </>
  );
};

export default HeroBanner;
