import React, { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import MobileSolutionItem from "./MobileSolutionItem";

const MobileSolutions = () => {
  const { t } = useTranslation("home");
  const [tooltip, setTooltip] = useState(null);

  return (
    <div className="container xl:hidden">
      <div className="relative mt-12 flex justify-evenly">
        {tooltip && (
          <div className="absolute bottom-full left-1/2 z-20 mb-4 w-max max-w-xs -translate-x-1/2 rounded bg-purple-700 p-3 text-center text-xs font-semibold shadow">
            {tooltip}
          </div>
        )}
        <MobileSolutionItem
          image="/home/hero/call.svg"
          alt="Phone Icon"
          title={t`banner.telephone.title`}
          subtitle={t`banner.telephone.subtitle`}
          setTooltip={setTooltip}
        />
        <MobileSolutionItem
          image="/home/hero/chat-2.svg"
          alt="Chat Icon"
          title={t`banner.chat-advice.title`}
          subtitle={t`banner.chat-advice.subtitle`}
          setTooltip={setTooltip}
        />
        <MobileSolutionItem
          image="/home/hero/video-circle.svg"
          alt="Video Icon"
          title={t`banner.video-advice.title`}
          subtitle={t`banner.video-advice.subtitle`}
          setTooltip={setTooltip}
        />
      </div>
    </div>
  );
};

export default MobileSolutions;
