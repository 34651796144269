import clsx from "clsx";
import Image from "next/image";
import CrossLink from "@/components/common/CrossLink";

const TYPES_TO_SIZE = {
  small: "w-[255px] px-4",
  medium: "w-[349px] px-8",
  large: "w-[442px] px-16",
};

const AdvertisementCard = ({ advertisement, className }) => {
  const hasPicture = !!advertisement.picture;

  return (
    <div
      className={clsx(
        "relative flex h-full select-none flex-col items-center overflow-hidden rounded-lg bg-purple-800 px-4 pt-6 pb-4 shadow lg:py-6",
        TYPES_TO_SIZE[advertisement.size],
        className
      )}
    >
      <Image
        src={hasPicture ? advertisement.picture : "/home/offer-card-bg.svg"}
        className="max-h-28 w-auto max-w-full lg:max-h-64"
        alt={advertisement.headline}
        layout="fill"
        objectFit="cover"
      />
      <div
        className={clsx(
          "relative flex grow flex-col items-center",
          hasPicture ? "justify-end" : "justify-center gap-9"
        )}
      >
        <span className={clsx("text-center font-semibold", hasPicture ? "text-xl" : "text-3xl")}>
          {advertisement.headline}
        </span>
        <p className={clsx("text-center text-grey-800", hasPicture ? "mt-2.5 text-sm" : "text-xl")}>
          {advertisement.body}
        </p>
        <CrossLink
          href={advertisement.link}
          className={clsx("hover-orange", hasPicture ? "button mt-5 md:px-4" : "button-lg w-full px-0")}
        >
          {advertisement.buttonName}
        </CrossLink>
        {advertisement.disclaimer && <small className="text-center text-grey-800">{advertisement.disclaimer}</small>}
      </div>
    </div>
  );
};

export default AdvertisementCard;
