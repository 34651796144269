import "swiper/css";
import { Swiper } from "swiper/react";
import SwiperCore, { Mousewheel, Navigation } from "swiper/core";
import { useEffect, useRef } from "react";
import isMobile from "is-mobile";
import clsx from "clsx";
import IconChevronRight from "@/common/icons/chevron-right.svg";
import IconChevronLeft from "@/common/icons/chevron-left.svg";
import styles from "./Slider.module.css";

SwiperCore.use([Navigation, Mousewheel]);

const Slider = ({
  hack = true,
  text,
  children,
  className,
  slidesPerView = "auto",
  spaceBetween = 30,
  hackSpaceBetween = false,
  slideFastInDesktop = false,
  ...rest
}) => {
  const prevRef = useRef();
  const nextRef = useRef();
  const swiperRef = useRef(null);

  useEffect(() => {
    if (slidesPerView === "auto") {
      // fixes chrome scroll bug on slides per view is auto
      swiperRef.current?.update();
    }
  }, [slidesPerView]);

  return (
    <>
      <div className={className}>{text}</div>
      <div className="relative w-full" style={{ "--slider-space-between": hackSpaceBetween ? 0 : `${spaceBetween}px` }}>
        <button
          type="button"
          className="button-icon absolute top-1/2 -left-3 z-10 mr-3 hidden -translate-y-1/2 rounded-full bg-white shadow disabled:hidden lg:flex"
          aria-label="Previous"
          ref={prevRef}
        >
          <IconChevronLeft className="h-8 w-8" />
        </button>
        <button
          type="button"
          className="button-icon absolute top-1/2 -right-6 z-10 hidden -translate-y-1/2 rounded-full bg-white shadow disabled:hidden lg:flex"
          aria-label="Next"
          ref={nextRef}
        >
          <IconChevronRight className="h-8 w-8" />
        </button>
        <Swiper
          cssMode={isMobile()}
          mousewheel={{ forceToAxis: true }}
          slidesPerView={slidesPerView}
          spaceBetween={hackSpaceBetween ? spaceBetween : 0}
          onActiveIndexChange={(slider) => {
            if (hack) {
              slider.el.classList.toggle(styles.zero, slider.isBeginning);
              slider.el.classList.toggle(styles.last, slider.isEnd);
            }
          }}
          onInit={(swiper) => {
            if (!swiper.device.ios && !swiper.device.android) {
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.prevEl = prevRef.current;
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          className={hack ? clsx(styles.slider, styles.zero) : styles.slider}
          slidesPerGroup={1}
          breakpoints={slideFastInDesktop ? { 1024: { slidesPerGroup: 3 } } : {}}
          {...rest}
        >
          {children}
        </Swiper>
      </div>
    </>
  );
};

export default Slider;
