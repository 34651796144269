import { useEffect, useRef } from "react";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { setIsOpenCategoriesSidebar } from "@/store/commonSlice";
import { DotsHorizontalIcon } from "@heroicons/react/outline";
import BannerCategoryItem from "./BannerCategoryItem";

const BannerCategories = ({ mainCategories }) => {
  const menuRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!menuRef.current) throw Error("menuRef is not assigned");

    const menu = menuRef.current;
    const handle = () => {
      const menuOffsetTop = menu.offsetTop;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < menu.children.length; i++) {
        const child = menu.children[i];
        // eslint-disable-next-line no-continue
        if (child.tagName !== "LI") continue;
        child.classList.remove("hidden");

        if (child.offsetTop > menuOffsetTop) {
          child.classList.add("hidden");
        }
      }
    };

    handle();

    window.addEventListener("resize", handle);
    return () => window.removeEventListener("resize", handle);
  }, []);

  return (
    <div className="hidden bg-purple-800 lg:block">
      <div className="container flex h-[42px] items-start gap-3">
        <ul className="flex flex-wrap items-center gap-3" ref={menuRef}>
          {mainCategories.map((c) =>
            c.sub.length > 0 ? (
              <BannerCategoryItem key={c.main.id} category={c} />
            ) : (
              <li className="select-none" key={c.main.id}>
                <Link href={`/category/${c.main.slug}`}>
                  <a className="block rounded-lg border border-grey-400 py-2 px-5 font-semibold text-grey-900 hover:border-purple md:px-6">
                    {c.main.name}
                  </a>
                </Link>
              </li>
            )
          )}
        </ul>

        <button
          className="inline-flex items-center gap-2 rounded-lg border border-grey-400 py-2 px-5 font-semibold text-grey-900 hover:border-purple md:px-6"
          type="button"
          onClick={() => dispatch(setIsOpenCategoriesSidebar(true))}
        >
          <DotsHorizontalIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default BannerCategories;
