import React, { useMemo } from "react";
import useTranslation from "next-translate/useTranslation";

const CallConsultantButtonTariff = ({ connects }) => {
  const { t } = useTranslation("common");

  const minTariff = useMemo(() => {
    if (connects.length > 1) {
      return connects.reduce((min, connection) => {
        if (connection.type === "vas") {
          return min;
        }
        if (connection.tariff < min) {
          return connection.tariff;
        }
        return min;
      }, Infinity);
    }

    return connects[0].tariff ?? 0;
  }, [connects]);

  return (
    <span className="ml-1 text-xs font-medium text-white">
      {t(connects.length > 1 ? "format.from-tariff" : "format.tariff", { tariff: minTariff / 100 })}
    </span>
  );
};

export default CallConsultantButtonTariff;
