import Link from "next/link";
import { Fragment, useMemo, useRef } from "react";
import useTranslation from "next-translate/useTranslation";
import usePopper from "@/common/hook/usePopper";
import clsx from "clsx";

const BannerCategoryItem = ({ category }) => {
  const { subCategories, subMethodCategories } = useMemo(
    () =>
      category.sub?.reduce(
        (stack, curr) => {
          stack[curr.method ? "subMethodCategories" : "subCategories"].push(curr);
          return stack;
        },
        { subCategories: [], subMethodCategories: [] }
      ),
    [category.sub]
  );
  const { t } = useTranslation("home");
  const popperRef = useRef(null);
  const { referenceRef, enable, disable, isEnable } = usePopper(popperRef, { placement: "bottom-start" });

  return (
    <>
      <li
        className="select-none"
        ref={referenceRef}
        onMouseEnter={enable}
        onMouseLeave={disable}
        onFocus={enable}
        onBlur={disable}
      >
        <Link href={`/category/${category.main.slug}`}>
          <a className="block rounded-lg border border-grey-400 py-2 px-5 font-semibold text-grey-900 hover:border-purple md:px-6">
            {category.main.name}
          </a>
        </Link>
      </li>

      <div
        tabIndex={-1}
        className={clsx("z-30 select-none", !isEnable && "hidden")}
        ref={popperRef}
        onMouseEnter={enable}
        onMouseLeave={disable}
      >
        {category.sub.length > 0 && (
          <div className="pt-2">
            <div className="flex divide-x divide-grey-300 overflow-hidden rounded-lg bg-white pb-4 shadow">
              <div>
                <div className="w-full py-4 px-6 font-semibold">{t`banner.category.title`}</div>
                <div className="grid grid-flow-col grid-rows-6-auto items-center gap-1.5">
                  {subCategories.map((sub) => (
                    <Fragment key={sub.id}>
                      <Link href={`/category/${sub.slug}`}>
                        <a className="min-w-[12rem] whitespace-nowrap py-2 px-6 hover:bg-grey-100">{sub.name}</a>
                      </Link>
                    </Fragment>
                  ))}
                </div>
              </div>
              {subMethodCategories.length > 0 && (
                <div>
                  <div className="w-full py-4 px-6 font-semibold">{t`banner.category.method`}</div>
                  <div className="grid grid-flow-col grid-rows-6-auto items-center gap-1.5">
                    {subMethodCategories.map((sub) => (
                      <Fragment key={sub.id}>
                        <Link href={`/category/${sub.slug}`}>
                          <a className="min-w-[12rem] whitespace-nowrap py-2 px-6 hover:bg-grey-100">{sub.name}</a>
                        </Link>
                      </Fragment>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BannerCategoryItem;
