import { SwiperSlide } from "swiper/react";
import Trans from "next-translate/Trans";
import { useEffect, useMemo, useState } from "react";
import dynamic from "next/dynamic";
import Slider from "@/components/common/Slider";
import shuffle from "@/utils/shuffle";
import BlogCard from "@/components/common/BlogCard";
import useCallConsultant from "@/common/hook/useCallConsultant";
import HighlightLink from "./HighlightLink";
import AdvertisementCard from "./AdvertisementCard";
import ConsultantCard from "./ConsultantCard";

const CallConsultantPopup = dynamic(() => import("@/components/common/CallConsultantPopup"));

const CategorySlider = ({ category, findAdvertisementsByCategory, blogPosts, consultants }) => {
  const advertisements = useMemo(
    () => findAdvertisementsByCategory(category.id),
    [findAdvertisementsByCategory, category.id]
  );
  const [blogsAndAdvertisements, setBlogsAndAdvertisements] = useState([]);
  const [callConsultant, setCallConsultant] = useState(null);
  const {
    call,
    activeComponent: ActiveComponent,
    setActivePopup,
    callType,
  } = useCallConsultant(() => setActivePopup(null));

  useEffect(() => {
    // DONT USE useMemo HERE BECAUSE CORRUPT
    setBlogsAndAdvertisements(shuffle([...(blogPosts ?? []), ...advertisements]));
  }, [blogPosts, advertisements]);

  return (
    <>
      <Slider
        slideFastInDesktop
        className="mt-20 lg:mt-24"
        text={
          <>
            <h2 className="text-center text-xl font-semibold lg:text-left lg:text-4xl">
              <Trans
                i18nKey="home:consultant-slider.title"
                components={{ highlight: <HighlightLink categoryId={category.slug} /> }}
                values={{ category: category.name }}
              />
            </h2>
            {category.shortSubtitle && <p className="mt-3 hidden text-grey-700 md:block">{category.shortSubtitle}</p>}
          </>
        }
      >
        {consultants?.map((consultant) => (
          <SwiperSlide key={consultant.id} className="py-4 lg:py-8">
            <ConsultantCard
              consultant={consultant}
              call={() => {
                setCallConsultant(consultant);
                if (consultant.connects.length === 1) {
                  call(consultant, consultant.connects[0]);
                }
              }}
            />
          </SwiperSlide>
        ))}
      </Slider>
      {ActiveComponent && (
        <ActiveComponent consultant={callConsultant} onClose={() => setActivePopup(null)} callType={callType} />
      )}
      {callConsultant !== null && callConsultant.connects.length > 1 && (
        <CallConsultantPopup consultant={callConsultant} onClose={() => setCallConsultant(null)} />
      )}
      {blogsAndAdvertisements.length > 0 && (
        <Slider slideFastInDesktop>
          {blogsAndAdvertisements.map((object) => (
            <SwiperSlide key={object.id} className="!h-auto py-4 lg:py-8">
              {object.size ? <AdvertisementCard advertisement={object} /> : <BlogCard blog={object} />}
            </SwiperSlide>
          ))}
        </Slider>
      )}
    </>
  );
};

export default CategorySlider;
