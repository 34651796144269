import { useMemo } from "react";
import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import UserState from "@/common/models/UserState";
import friendlySeconds from "@/utils/friendlySeconds";

const BREAK_TIME = 60 * 60 * 24;

const ConsultantCardStateText = ({ state, stateChangedAt, className }) => {
  const { t } = useTranslation("common");

  const stateText = useMemo(() => {
    if (UserState.OFFLINE.equals(state)) {
      if (stateChangedAt) {
        const stateChangeSeconds = Math.round((Date.now() - new Date(stateChangedAt).getTime()) / 1000);
        return t("format.last-seen", {
          time: t("format.time-ago", {
            time: stateChangeSeconds > BREAK_TIME ? t("format.few-time.day") : friendlySeconds(stateChangeSeconds, t),
          }),
        });
      }

      return "";
    }

    return t(UserState[state].languageKey);
  }, [state, stateChangedAt, t]);

  return <div className={clsx("text-xs font-semibold", UserState[state].textColor, className)}>{stateText}</div>;
};

export default ConsultantCardStateText;
