import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import React, { useMemo } from "react";
import UserState from "@/common/models/UserState";
import CallConsultantButtonTariff from "@/components/common/CallConsultantPopup/CallConsultantButtonTariff";

const CallConsultantButton = ({ state, onClick, connects, grow = true, small = false }) => {
  const { t } = useTranslation("common");

  const [buttonClassName, buttonText] = useMemo(() => {
    if (connects?.length) {
      if (UserState.ONLINE.equals(state)) {
        return [
          "bg-green hover:bg-green-900",
          connects.length === 1 ? t(`connections.${connects[0].type}`) : t`call-consultant-popup.button.now-available`,
        ];
      }

      return [
        "bg-grey-600 hover:bg-grey-500",
        connects[0].type === "phone"
          ? t`call-consultant-popup.request-callback.button`
          : t`call-consultant-popup.button.book-appointment`,
      ];
    }

    return ["bg-grey-600 hover:bg-grey-500 cursor-not-allowed", t`call-consultant-popup.button.not-available`];
  }, [connects, state, t]);

  return (
    <button
      type="button"
      className={clsx("button", grow && "w-full", buttonClassName, small && "text-xs")}
      onClick={!connects || connects.length === 0 ? undefined : onClick}
    >
      <span className="font-semibold">{buttonText}</span>
      {connects && connects.length > 0 && (UserState.ONLINE.equals(state) || connects[0].type === "phone") && (
        <CallConsultantButtonTariff connects={UserState.ONLINE.equals(state) ? connects : [connects[0]]} />
      )}
    </button>
  );
};

export default CallConsultantButton;
