import clsx from "clsx";
import { createElement, forwardRef } from "react";

const COMMON = "flex-shrink-0 inline-block";

const SUB_CATEGORY = clsx(COMMON, "hover:text-purple button-text");

const COMMON_SUB_2_AND_CATEGORY = "py-2 px-5 md:px-6 hover:border-purple button-outline";
const CATEGORY = clsx(COMMON, COMMON_SUB_2_AND_CATEGORY, "text-base border-transparent");
const SUB_CATEGORY_2 = clsx(COMMON, COMMON_SUB_2_AND_CATEGORY, "text-sm");

export const BUTTON_CATEGORY_TYPES = {
  CATEGORY: clsx(CATEGORY, "text-grey-900 hover:text-grey-900 bg-grey-100"),
  CATEGORY_ACTIVE: clsx(CATEGORY, "text-purple hover:text-purple bg-purple-900"),
  SUB_CATEGORY: clsx(SUB_CATEGORY, "text-grey-700"),
  SUB_CATEGORY_ACTIVE: clsx(SUB_CATEGORY, "text-purple underline"),
  SUB_CATEGORY_2: clsx(SUB_CATEGORY_2, "text-grey-900 hover:text-grey-900 bg-white border-grey-400"),
  SUB_CATEGORY_2_ACTIVE: clsx(SUB_CATEGORY_2, "text-purple hover:text-purple bg-purple-900 border-transparent"),
};

const ButtonCategoryComponent = ({ children, type, tag = "button", className, ...props }, ref) =>
  createElement(
    tag,
    {
      ...props,
      ref,
      className: clsx(type, className),
      type: tag === "button" ? "button" : undefined,
    },
    children
  );

const ButtonCategory = forwardRef(ButtonCategoryComponent);
export default ButtonCategory;
