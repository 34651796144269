import Image from "next/image";
import clsx from "clsx";
import { useMemo } from "react";
import dynamic from "next/dynamic";
import Badge from "@/components/common/Badge";
import CallConsultantButton from "@/components/common/CallConsultantPopup/CallConsultantButton";
import CrossLink from "@/components/common/CrossLink";
import ConsultantCardProfilePhoto from "@/components/home/CategorySliders/ConsultantCardProfilePhoto";
import ConsultantCardStateText from "@/components/common/ConsultantCard/ConsultantCardStateText";

const ConsultantCardVerified = dynamic(() => import("@/components/common/ConsultantCard/ConsultantCardVerified"));

const ConsultantCard = ({ consultant, className, call }) => {
  const qualifications = useMemo(
    () => consultant.qualifications.slice(0, 3).sort((a, b) => a.name.length - b.name.length),
    [consultant.qualifications]
  );

  return (
    <div
      className={clsx(
        "flex  w-[80vw] min-w-[280px] max-w-xs select-none flex-col overflow-hidden rounded-lg bg-white shadow-lg md:w-72 lg:w-80 lg:hover:scale-105 xl:w-[25vw] 2xl:w-[20vw]",
        className
      )}
    >
      <div className="relative flex h-24 items-start justify-end bg-purple-800 p-3">
        <Image src="/home/consultant-card-bg.jpg" layout="fill" objectFit="cover" quality={100} alt="Card Background" />
      </div>

      <div className="flex grow flex-col px-5 pb-6">
        <div className="-mt-16 flex font-semibold">
          <ConsultantCardProfilePhoto
            state={consultant.state}
            sound={consultant.sound}
            picture={consultant.picture}
            slug={consultant.slug}
            name={consultant.displayName}
          />
          <ConsultantCardStateText
            className="mt-18 ml-3"
            state={consultant.state}
            stateChangedAt={consultant.stateChangedAt}
          />
        </div>

        <div className="my-3 flex items-center gap-2">
          <CrossLink href={`/${consultant.slug}`} className="truncate text-xl font-semibold text-grey">
            {consultant.displayName}
          </CrossLink>
          {consultant.isVerified && <ConsultantCardVerified />}
        </div>

        {/* consultant badges 52px = 24 (badge height) * 2 (line count) + 4 (gap) * 1 (line count - 1) */}
        <div className="mb-2 flex h-[52px] flex-wrap items-start gap-x-2 gap-y-1 overflow-y-hidden">
          {qualifications.map((q) => (
            <Badge color="grey" key={q.id} big={false}>
              {q.name}
            </Badge>
          ))}
        </div>

        {/* consultant description */}
        <p className="mt-3 mb-5 h-16 text-xs text-grey-700 line-clamp-4">{consultant.introduction}</p>

        {/* to the profile */}
        <div className="flex grow flex-col items-center justify-end">
          <CallConsultantButton connects={consultant.connects} state={consultant.state} onClick={call} />
        </div>
      </div>
    </div>
  );
};

export default ConsultantCard;
